<template>
    <div>
        <a-card style="margin-bottom:20px">
            <a-form layout="inline">
                <a-form-item class="dep" label="标题:">
                    <a-input v-model="searchParam" placeholder="标题"></a-input>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" icon="search" @click="handleClickSearch">
                        搜索
                    </a-button>    
                </a-form-item>
            </a-form>
        </a-card>
        <a-card>
            <a-row style="text-align:right;margin-bottom:20px;">
                <a-button type="primary" @click="handleAdd">新增</a-button>
            </a-row>
            <a-table
                :columns="columns"
                :data-source="tableData"
                :row-key="(record) => record._id"
                :pagination="pagination"
                :loading="loading"
                :scroll="{x:1200}"
                @change="handleTableChange"
                >
                <template slot="background_img" slot-scope="background_img">
                    <img :src="background_img" style="height:100px;width:100px;" @click="preImg(background_img)" />
                </template>
                <div slot="time" slot-scope="text">
                    {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                </div>

                <a-tag slot="activity_status" slot-scope="text" :color="text===0?'blue':text===1?'green':'red'">{{text===0?'未开始':text===1?'进行中':'已结束'}}</a-tag>

                
                 <template slot="operation"
                    slot-scope="text,record">
                    <div class="operation-wrapper">
                        <!-- <a-button type="link"  @click="handleUpdate(record)">修改</a-button> -->
                        <a-button type="link"  @click="handleGetDetail(record)">查看</a-button>
                        <a-button type='link' style="color:red;" @click="handleDelete(record)">删除</a-button>
                        <a-button type="link" style="color:red;" @click="handleStop(record)">停止</a-button>
                    </div>  
                </template>
            </a-table>
            <a-modal v-model="visible" title="新建邀请有礼" width="700px" @ok="handleSubmit">
                <a-form>
                    <a-form-item 
                        label="活动名称:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <a-input v-model="addform.activity_name" placeholder="活动名称"></a-input>
                    </a-form-item>
                    <a-form-item 
                        label="时间范围:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <a-range-picker @change="handleCustomerDateChange" v-model="customerDateRange"/>
                    </a-form-item>
                    <a-form-item 
                        label="使用成员:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <a-tree-select
                            v-model="addform.staff_ids"
                            :tree-data="userList"
                            tree-checkable
                            :show-checked-strategy="SHOW_PARENT"
                            search-placeholder="Please select"
                            :replaceFields="{children:'child',title:'userid',key:'userid',value:'userid'}"
                            @search="handleSearchUser"
                            :dropdownStyle="{height:'300px'}"
                        />
                    </a-form-item>
                    <a-form-item 
                        label="目标人数:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <a-input type="number" v-model="addform.join_num"></a-input>
                    </a-form-item>
                    <a-form-item 
                        label="上传海报:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <a-upload
                            list-type="picture-card"
                            class="avatar-uploader"
                            accept='.jpg,.jpeg,.png'
                            :show-upload-list="false"
                            action="scrm/upload.file"
                            :before-upload="beforeUpload"
                            @change="handleImgChange"
                        >
                            <img v-if="addform.background_img" :src="addform.background_img" alt="avatar" style="height:100px;width:100px" />
                            <div v-else>
                            <a-icon :type="imgLoading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">
                                上传
                            </div>
                            </div>
                        </a-upload>
                    </a-form-item>
                    <a-form-item
                        label="活动规则:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <a-input type="textarea" :autosize="{ minRows: 6}" v-model="addform.rule" placeholder="活动规则"></a-input>
                    </a-form-item>
                    <a-form-item
                        label="自动回复:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <div class="tab-view">
                            <div class="tab-item" :class="{'active':activeTab==='1'}" @click="handleChangeTab('1')">欢迎语</div>
                            <div class="tab-item" :class="{'active':activeTab==='2'}" @click="handleChangeTab('2')">助力中</div>
                            <div class="tab-item" :class="{'active':activeTab==='3'}" @click="handleChangeTab('3')">助力成功</div>
                            <div class="tab-item" :class="{'active':activeTab==='4'}" @click="handleChangeTab('4')">活动未开始</div>
                            <div class="tab-item" :class="{'active':activeTab==='5'}" @click="handleChangeTab('5')">活动已结束</div>
                        </div>
                        <a-input v-if="activeTab==='1'" :autosize="{ minRows: 6}" type="textarea" v-model="addform.reply_welcome"></a-input>
                        <a-input v-if="activeTab==='2'" :autosize="{ minRows: 6}" type="textarea" v-model="addform.reply_loading"></a-input>
                        <a-input v-if="activeTab==='3'" :autosize="{ minRows: 6}" type="textarea" v-model="addform.reply_success"></a-input>
                        <a-input v-if="activeTab==='4'" :autosize="{ minRows: 6}" type="textarea" v-model="addform.reply_not_start"></a-input>
                        <a-input v-if="activeTab==='5'" :autosize="{ minRows: 6}" type="textarea" v-model="addform.reply_end"></a-input>
                    </a-form-item>
                    <a-form-item 
                        label="奖品配置:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <div>满足<a-input style="width:120px" type="number" min="1" v-model="addform.help_num"></a-input>人后发放</div>
                        
                    </a-form-item>
                     <a-form-item 
                        label="奖品选择:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <a-tree-select
                            v-model="addform.sku_id"
                            :tree-data="skuList"
                            :multiple="false"
                            :show-checked-strategy="SHOW_PARENT"
                            search-placeholder="Please select"
                            :replaceFields="{title:'sku_name',key:'_id',value:'_id'}"
                            @search="handleSearchSku"
                            :dropdownStyle="{height:'300px'}"
                        />
                    </a-form-item>
                    <a-form-item 
                        label="设置奖品总数量:"
                        :label-col="{span:6}" 
                        :wrapper-col="{ span: 14 }"
                    >
                        <a-input type="number" v-model="addform.sku_stock"></a-input>
                    </a-form-item>
            </a-form>
            </a-modal>
            <a-modal v-model="isPre" title="预览" width="600px" :footer="null" style="text-align:center;">
                <img :src="background_img" style="width:500px;height:500px;">
            </a-modal>
        </a-card>
    </div>
</template>
<script>
const columns = [
    {
      title: "标题",
      dataIndex: "activity_name",
      align: "center",
      ellipsis: true
    },
    {
      title: "海报",
      dataIndex: "background_img",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'background_img' },
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'time' },
    },
    {
        title: '状态',
        dataIndex: 'activity_status',
        align:'center',
        scopedSlots: { customRender: "activity_status" }
    },
    {
        title: '活动开始时间',
        dataIndex: 'start_time',
        align:'center',
        scopedSlots: { customRender: "time" }
    },
    {
        title: '活动结束时间',
        dataIndex: 'end_time',
        align:'center',
        scopedSlots: { customRender: "time" }
    },{
        title: '操作',
        width:120,
        ellipsis: true,
        align:'center',
        scopedSlots: { customRender: "operation" }
    }
    
]
import {
    mapState
  } from 'vuex'
  import { TreeSelect } from 'ant-design-vue';
  const SHOW_PARENT = TreeSelect.SHOW_PARENT;
  import moment from 'moment';
export default {
    name:'invite-gift',
    data(){
        return {
            SHOW_PARENT,
            searchParam:'',
            activeTab:'1',
            loading:false,
            visible:false,
            imgLoading:false,
            customerDateRange:undefined,
            tableData:[],
            columns,
            isPre:false,
            addform:{},
            userList:[],
            skuList:[],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
        }
    },
     computed: {
        ...mapState(['mqttInit'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.getUserList()
                    this.getSkuList()
                    this.handleClickSearch()
                }
            },
            immediate:true
        }
    },
    methods:{
        moment,
        handleClickSearch(){
            this.pagination.current = 1
            this.handleSearchList()
        },
        handleSearchList(){
            this.loading = true
            let payload = {
                msgId:'getTaskActivityList',
                data:{
                    pageSize:this.pagination.pageSize,
                    pageNum:this.pagination.current,
                    activity_name:this.searchParam
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/taskActivityService/getTaskActivityList",
                payload,
                },
                (topic, data) => {
                    if(data.code===200){
                        
                        this.loading = false
                        this.tableData = data.data.res
                        this.pagination.total = data.data.count
                    }else{
                        this.loading = false
                        this.$message.error('查询失败')
                    }
                },
                this
            );
        },
        handleAdd(){
            this.visible = true
            
        },
        handleGetDetail(){
        },
        handleUpdate(){

        },
        preImg(img){
            this.background_img = img
            this.isPre = true
        },
        handleSubmit(){
            
            let {activity_name,
                start_time,
                end_time,
                staff_ids,
                join_num,
                help_num,
                background_img,
                rule,
                reply_welcome,
                reply_loading,
                reply_success,
                reply_not_start,
                reply_end,
                sku_id,sku_stock
                } = this.addform

                let gift_config_list = [{sku_id,sku_stock}];
                let replyMessage ={reply_welcome,reply_loading,reply_success,reply_not_start,reply_end}

                if(!activity_name){
                    this.$message.error('请输入标题')
                    return
                }
                if(!start_time|| !end_time){
                    this.$message.error('请先选择时间范围')
                    return
                }
                if(staff_ids.length===0){
                    this.$message.error('请选择员工，此为必须')
                    return
                } 
                if(!join_num){
                    this.$message.error('请设置目标人数')
                    return
                } 
                if(!background_img){
                    this.$message.error('请上传海报')
                    return
                } 
                if(!rule){
                    this.$message.error('请填写活动规则')
                    return
                }
                if(!sku_stock){
                    this.$message.error('请设置奖品数量')
                    return
                }
                if(!sku_id){
                    this.$message.error('请选择奖品')
                    return
                }
                if(!help_num){
                    this.$message.error('请设置奖品发放条件')
                    return
                }
                
                this.visible = false

                let obj = {activity_name,staff_ids,start_time,end_time,join_num,help_num,background_img,rule,replyMessage,gift_config_list}
                
            let payload = {
                msgId:'saveTaskActivity',
                data:obj
            }
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/taskActivityService/saveTaskActivity",
                    payload,
                },
                (topic, data) => {
                    this.$message.success('新增成功')
                    this.handleClickSearch()
                },
                this
            );
        },
        handleDelete(item){
            this.$confirm({
                title: '提示',
                content: `是否确认删除本次活动?`,
                onOk:()=>{
                    let payload = {
                        msgId:'deleteTaskActivity',
                        data: {
                            id:item._id
                        },
                    };
                    this.$mqtt.doPublish(
                        {
                        pubTopic: "scrm/taskActivityService/deleteTaskActivity",
                        payload,
                        },
                        (topic, data) => {
                            if(data.code==200){
                                this.$message.info('删除成功')
                                this.handleSearchList()
                            }
                        },
                        this
                    );
                }})

        },
        handleStop(item){
            this.$confirm({
                title: '提示',
                content: `是否确认停止本次活动?`,
                onOk:()=>{
                    let payload = {
                        msgId:'stopTaskActivity',
                        data: {
                            id:item._id
                        },
                    };
                    this.$mqtt.doPublish(
                        {
                        pubTopic: "scrm/taskActivityService/stopTaskActivity",
                        payload,
                        },
                        (topic, data) => {
                            if(data.code==200){
                                this.$message.info('成功停止')
                                this.handleSearchList()
                            }
                        },
                        this
                    );
                }})

        },
        handleChangeTab(val){
            this.activeTab = val
        },
        handleTableChange(pagination){
            this.pagination = pagination
            this.handleSearchList()
        },
        disabledDate(current) {
            return current && current > moment(new Date()).endOf('day');
        },
        handleCustomerDateChange(date, dateString){
            this.addform.start_time = new Date(dateString[0]).getTime()
            this.addform.end_time = new Date(dateString[1]).getTime()
        },
        handleSearchUser(val){
            this.getUserList(val)
        },
        handleSearchSku(val){
            this.getSkuList(val)
        },
        beforeUpload(){

        },
        //上传图片响应
        handleImgChange(info){
            if (info.file.status === 'uploading') {
                this.imgLoading = true;
                return;
            }
            if (info.file.status === 'done') {
                this.imgLoading = false;
                this.addform.background_img = info.file.response.url
                
            }
        },
        getUserList(name=''){
            let payload = {
                msgId:'searchWxUserByPageOnChannel',
                data: {
                    pageSize:100,
                    pageNum:1
                },
            };
            if(name){
                payload.data.name = name
            }
            // console.log(payload)
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/weUser/searchWxUserByPage",
                    payload,
                },
                (topic, data) => {
                    // console.log(data)
                    if(data.code===200){
                        this.userList = data.data.res;
                    }
                },
                this
            );
        },
        getSkuList(name=''){
            let payload = {
                msgId:'listSku',
                data: {
                    pageSize:100,
                    pageNum:1,
                    status:2
                },
            };
            
            console.log(payload)
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/wxapi/listSku",
                    payload,
                },
                (topic, data) => {
                     console.log(data)
                    if(data.code===200){
                        this.skuList = data.data.res;
                    }
                },
                this
            );
        },
        
    }
}
</script>
<style lang="scss" scoped>
.operation-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .tab-view{
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #999;
        // border-bottom: 1px solid #e7e7e7;
        .tab-item{
            height: 50px;
            line-height: 50px;
            cursor: pointer;
        }
        .tab-item.active{
            color: #48A5A1;
            font-weight: bold;
        }
    }
</style>